<template>
  <div id="opening">
    <div id="wrap">
      <div class="openword">めんつゆは卵かけごはん史上最高の盟友だ。</div>
      <div class="openword sign">――R.Zonotaro</div>
    </div>
  </div>
</template>

<style>
/* special */
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

#opening {
  z-index: 100;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 255, 255, 1);
  top: 0;
  left: 0;
}

#wrap {
  width: 80%;
}

.openword {
  position: fixed;
  text-align: center;
  z-index: 120;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  inset: 0;
  margin: auto;
  width: 100vw;
  height: 1em;
  font-size: calc(100vmin / 24);
}

.sign {
  padding-top: 5em;
  text-align: right;
  margin-right: 5vw;
  font-size: calc(100vmin / 45);
}

.minibox {
  z-index: 100;
  opacity: 1;
  width: 20px;
  height: 20px;
  position: fixed;
}
</style>

<script>
import anime from 'animejs'
import $ from 'jquery'

export default {
  components: {

  },
  data() {

  },
  methods: {

  },
  created() {

  },
  mounted() {
    let openingWrapper = document.querySelector('.openword');
    openingWrapper.innerHTML = openingWrapper.textContent.replace(/\S/g, "<span class='letter'>$&</span>");

    for(let i = 0; i < 100; i++) {
      let r = Math.floor(Math.random() * 255);
      let g = Math.floor(Math.random() * 255);
      let b = Math.floor(Math.random() * 255);
      let rgba = "rgba(" + r + ", " + g + ", " + b + ", 1)";

      $('#opening').append('<div class="minibox" style="background-color: ' + rgba + ';"></div>');
    }

    anime.timeline({loop: false})
          .add({
          targets: '.minibox',
          opacity: [1, 0],
          rotateZ: function() {
            return [anime.random(-5, 5) + 'turn'];
          },
          scale: function() {
            return [anime.random(1, 5), 1];
          },
          top: function() {
            let pom = anime.random(0, 1);

            let result = 0;
            if(pom == 0) {
              result = -50;
            } else {
              result = 150;
            }
            return [result + 'vh', anime.random(0, 100) + '%'];
          },
          left: function() {
            return [anime.random(-50, 100) + 'vw', anime.random(0, 100) + '%'];
          },
          easing: 'easeOutQuart',
          duration: 60000,
          delay: (el, i) => i * 200
        }, -500)
        .add({
          targets: '.openword .letter',
          opacity: [0, 1],
          scale: [0.2, 1],
          translateY: ['5vh', 0],
          easing: 'easeInOutSine',
          delay: (el, i) => 50 * (i + 1),
          duration: 500
        }, 1000)
        .add({
          targets: '.sign',
          opacity: [0, 1],
          scale: [1, 1],
          translateX: ['-5vw', '0vw'],
          easing: 'easeOutSine',
          duration: 500
        }, 2000);
  }
}
</script>
